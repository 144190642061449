import React from 'react';

import './WebHostingDomainManagement.css';
import SEO from '../../containers/SEO/SEO';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faServer, faGlobe, faLock } from '@fortawesome/free-solid-svg-icons';
import image1 from '../../assets/cloud.jpg';

const WebHostingDomainManagement = () => {
    return (
        <div className="container" id="webhosting-domain-management">
              <SEO 
        title="Webhosting & Domainverwaltung in Bad Hersfeld" 
        description="Exabyte Webservices bietet zuverlässiges Webhosting und professionelle Domainverwaltung in Bad Hersfeld – schnelle, sichere und stabile Webpräsenzen." 
      />
            <div className="services">
                <h1 data-aos="fade-up">Webhosting & Domainverwaltung</h1>

                {/* Einleitender Abschnitt */}
                <section className="hosting-section" data-aos="fade-up">
                    <div className="card">
                        <h4 className="hosting-heading">Verlässliches Hosting und Domainverwaltung</h4>
                        <p className="hosting-text">
                            Wir bieten Ihnen zuverlässiges Hosting und eine umfassende Verwaltung Ihrer Domain, damit Ihre Website in jeder Situation online bleibt und 
                            Sie sich auf das Wesentliche konzentrieren können.
                        </p>
                        <img src={image1} alt="Hosting bei Exabyte Webservices" className="intro-image"  />
                    </div>
                </section>

                {/* Leistungen */}
                <section className="hosting-section" data-aos="fade-up" data-aos-delay="100">
                    <h4 className="hosting-heading">Unsere Hosting- und Domain-Dienstleistungen</h4>
                    <div className="card-container">
                        <div className="card small-card">
                            <FontAwesomeIcon icon={faServer} className="service-icon" />
                            <h5>Stabiles Webhosting</h5>
                            <p>Zuverlässiges Hosting, das jederzeit eine schnelle Ladezeit und optimale Leistung Ihrer Website sicherstellt.</p>
                        </div>

                        <div className="card small-card">
                            <FontAwesomeIcon icon={faGlobe} className="service-icon" />
                            <h5>Domainverwaltung</h5>
                            <p>Umfassende Domainverwaltung inklusive Registrierung und Erneuerung, damit Sie sich um nichts kümmern müssen.</p>
                        </div>

                        <div className="card small-card">
                            <FontAwesomeIcon icon={faLock} className="service-icon" />
                            <h5>Sicherheit & Backup</h5>
                            <p>Regelmäßige Backups und Sicherheitsmaßnahmen, um die Verfügbarkeit und den Schutz Ihrer Website zu gewährleisten.</p>
                        </div>
                    </div>
                </section>

                {/* Vorteile */}
                <section className="hosting-section" data-aos="fade-up" data-aos-delay="200">
                    <h4 className="hosting-heading">Vorteile unserer Hosting- und Domainverwaltung</h4>
                    <div className="advantages-grid">
                        <div className="advantage-card">
                            <FontAwesomeIcon icon={faServer} className="advantage-icon" />
                            <h5>Höchste Verfügbarkeit</h5>
                            <p>Unsere Infrastruktur stellt sicher, dass Ihre Website stets erreichbar ist, auch bei hohem Traffic.</p>
                        </div>

                        <div className="advantage-card">
                            <FontAwesomeIcon icon={faGlobe} className="advantage-icon" />
                            <h5>Einfache Verwaltung</h5>
                            <p>Konzentrieren Sie sich auf Ihr Geschäft, während wir Ihre Domainverwaltung vereinfachen.</p>
                        </div>

                        <div className="advantage-card">
                            <FontAwesomeIcon icon={faLock} className="advantage-icon" />
                            <h5>Maximale Sicherheit</h5>
                            <p>Schutz Ihrer Website vor Angriffen und Datenverlust durch unsere Sicherheitsprotokolle und regelmäßige Backups.</p>
                        </div>
                    </div>
                </section>

                {/* Call-to-Action */}
                <section className="hosting-section hosting-cta-section text-center" data-aos="fade-up" data-aos-delay="300">
                    <div className="card">
                        <h4 className="hosting-heading">Interesse an Webhosting und Domainverwaltung?</h4>
                        <p className="hosting-text">
                            Kontaktieren Sie uns, um mehr darüber zu erfahren, wie unser Hosting- und Domainservice Ihre Website zuverlässig und sicher online hält.
                        </p>
                        <a href='../#contactus' className="seo-cta-button btn btn-primary btn-lg">Kontaktieren Sie Uns</a>
                        </div>
                </section>
            </div>
        </div>
    );
};

export default WebHostingDomainManagement;
