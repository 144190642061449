import React, { useState, useEffect, memo } from 'react';
import './darkmode.css';
import '../../assets/variables.css';

const DarkMode = memo(() => {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const savedMode = localStorage.getItem('darkMode') === 'true';
    setDarkMode(savedMode);
  }, []); // Nur einmal aufgerufen

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
    localStorage.setItem('darkMode', darkMode.toString());
  }, [darkMode]);

  const handleToggle = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  return (
    <div className="toggle-container">
      <span style={{ color: darkMode ? 'white' : 'black' }}>🌞</span>
      <label htmlFor="dark-mode-checkbox" className="switch">
        <input
          id="dark-mode-checkbox"
          type="checkbox"
          onChange={handleToggle}
          checked={darkMode}
        />
        <span className="slider round"></span>
      </label>
      <span style={{ color: darkMode ? 'white' : 'black' }}>🌚</span>
    </div>
  );
});

export default DarkMode;
