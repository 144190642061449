import React from 'react';
import './ECommerce.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faUsers, faLaptopCode, faChartLine } from '@fortawesome/free-solid-svg-icons';
import eCommerceImage from '../../assets/sample-product.jpg';
import SEO from '../../containers/SEO/SEO';

const ECommerce = () => {
  return (
    <div className="container" id="ecommerce">
      <SEO 
        title="E-Commerce-Lösungen in Bad Hersfeld" 
        description="Professionelle E-Commerce-Webseiten von Exabyte Webservices – maßgeschneiderte Online-Shops für Unternehmen in Bad Hersfeld." 
      />
      <div className="services">
        <h1 data-aos="fade-up">Professionelle E-Commerce-Dienstleistungen</h1>

        {/* Einleitender Abschnitt */}
        <section className="ecommerce-section" data-aos="fade-up">
          <div className="card">
            <h4 className="ecommerce-heading">Ihr Erfolg im E-Commerce</h4>
            <p className="ecommerce-text">
              Wir bieten maßgeschneiderte Lösungen für Ihren Online-Shop, die sowohl funktional als auch optisch ansprechend sind. 
              Vertrauen Sie auf unsere Expertise, um Ihre Reichweite und Verkäufe zu steigern.
            </p>
            <img src={eCommerceImage} alt="E-Commerce" className="ecommerce-image" style={{ maxHeight: '600px' }} />
          </div>
        </section>

        {/* Vorteile erläutern */}
        <section className="ecommerce-section" data-aos="fade-up" data-aos-delay="100">
          <h4 className="ecommerce-heading">Vorteile unserer E-Commerce-Dienstleistungen</h4>
          <div className="advantages-grid">
    
            <div className="advantage-card">
              <FontAwesomeIcon icon={faCheckCircle} className="advantage-icon" />
              <h5>Optimierte Benutzerfreundlichkeit</h5>
              <p>Ein benutzerfreundliches Design steigert die Zufriedenheit und die Verweildauer Ihrer Kunden.</p>
            </div>
            <div className="advantage-card">
              <FontAwesomeIcon icon={faCheckCircle} className="advantage-icon" />
              <h5>Skalierbarkeit</h5>
              <p>Unsere Lösungen wachsen flexibel mit Ihrem Unternehmen und den steigenden Anforderungen.</p>
            </div>
            <div className="advantage-card">
              <FontAwesomeIcon icon={faCheckCircle} className="advantage-icon" />
              <h5>Vertrauenswürdigkeit</h5>
              <p>Sichere Zahlungsprozesse und Datenschutz schaffen Vertrauen bei Ihren Kunden.</p>
            </div>
          </div>
        </section>

        {/* Zusätzliche Dienstleistungen */}
        <section className="ecommerce-section" data-aos="fade-up" data-aos-delay="200">
          <h4 className="ecommerce-heading">Unsere zusätzlichen E-Commerce-Dienstleistungen</h4>
          <div className="services-grid">
            <div className="service-card">
              <FontAwesomeIcon icon={faLaptopCode} className="service-icon" />
              <h5>Entwicklung von Online-Shops</h5>
              <p>Maßgeschneiderte, moderne Online-Shops, die funktional und ansprechend sind.</p>
            </div>
            <div className="service-card">
              <FontAwesomeIcon icon={faChartLine} className="service-icon" />
              <h5>SEO und Marketing</h5>
              <p>Optimierte SEO-Strategien, um Ihre Sichtbarkeit in Suchmaschinen zu steigern.</p>
            </div>
            <div className="service-card">
              <FontAwesomeIcon icon={faUsers} className="service-icon" />
              <h5>Kundensupport und Wartung</h5>
              <p>Wir bieten kontinuierliche Unterstützung und regelmäßige Updates für Ihren Shop.</p>
            </div>
          </div>
        </section>

        {/* Erklärung: Warum eine Agentur? */}
        <section className="ecommerce-section" data-aos="fade-up" data-aos-delay="300">
          <h4 className="ecommerce-heading">Warum eine Agentur beauftragen?</h4>
          <p className="ecommerce-text">
            Eine erfahrene Agentur bringt technisches Know-how und bewährte Strategien mit, um Ihren Online-Shop erfolgreich zu machen.
            So können Sie sich auf Ihr Kerngeschäft konzentrieren, während wir uns um die technischen Details kümmern.
          </p>
          <p className="ecommerce-text">
            Von der Optimierung der Benutzererfahrung bis hin zur Verwaltung der Sicherheit – wir sorgen dafür, dass Ihr E-Commerce reibungslos funktioniert und wächst.
          </p>
        </section>

        {/* Call-to-Action */}
        <section className="ecommerce-section ecommerce-cta-section text-center" data-aos="fade-up" data-aos-delay="400">
          <div className="card">
            <h4 className="ecommerce-heading">Interessiert an unseren E-Commerce-Dienstleistungen?</h4>
            <p className="ecommerce-text">
              Kontaktieren Sie uns noch heute, um mehr über unsere maßgeschneiderten E-Commerce-Lösungen zu erfahren.
            </p>
            <a href='../#contactus' className="seo-cta-button btn btn-primary btn-lg">Kontaktieren Sie Uns</a>
            </div>
        </section>
      </div>
    </div>
  );
};

export default ECommerce;
