import { Helmet } from "react-helmet-async";

const SEO = ({ title, description }) => {
  return (
    <Helmet>
      <title>{title ? `${title} - Exabyte Webservices` : "Exabyte Webservices"}</title>
      <meta name="description" content={description || "Professionelle Webentwicklung & SEO in Bad Hersfeld"} />
      <link rel="canonical" href={window.location.href} />
    </Helmet>
  );
};

export default SEO;
