import React from 'react';
import SEO from '../../containers/SEO/SEO';

import './MaintenanceSupport.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faShieldAlt, faTools, faSyncAlt, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import image1 from '../../assets/servercable.jpg';

const MaintenanceSupport = () => {

    return (

        <div className="container" id="maintenance-support">
             <SEO 
        title="Wartung & Support für Webpräsenzen in Bad Hersfeld" 
        description="Exabyte Webservices bietet zuverlässige Wartung und Support für Ihre Webpräsenz in Bad Hersfeld – sichere und leistungsstarke Websites garantiert." 
      />

            <div className="services">

                <h1 data-aos="fade-up">Wartung & Support</h1>



                {/* Einleitender Abschnitt */}

                <section className="maintenance-section" data-aos="fade-up">

                    <div className="card">

                        <h4 className="maintenance-heading">Professioneller Wartungs- und Support-Service</h4>

                        <p className="maintenance-text">

                            Unser Team bietet kontinuierliche Wartung und technische Unterstützung, um sicherzustellen, dass Ihre Website immer auf dem neuesten Stand und sicher ist.

                            Mit unseren umfassenden Wartungsdiensten bleibt Ihre Website optimal und leistungsfähig.

                        </p>

                        <img src={image1} alt="Wartung" className="maintenance-image" style={{ maxHeight: '600px' }} />

                    </div>

                </section>



                {/* Leistungen */}

                <section className="maintenance-section" data-aos="fade-up" data-aos-delay="100">

                    <h4 className="maintenance-heading">Unsere Wartungs- und Support-Leistungen</h4>

                    <div className="card-container">

                        <div className="card small-card">

                            <FontAwesomeIcon icon={faShieldAlt} className="service-icon" />

                            <h5>Sicherheit & Schutz</h5>

                            <p>Regelmäßige Sicherheitsupdates und Überwachung, um die Sicherheit Ihrer Website zu gewährleisten.</p>

                        </div>



                        <div className="card small-card">

                            <FontAwesomeIcon icon={faTools} className="service-icon" />

                            <h5>Technischer Support</h5>

                            <p>Unser Team ist jederzeit für Sie da, um technische Probleme schnell und effizient zu lösen.</p>

                        </div>



                        <div className="card small-card">

                            <FontAwesomeIcon icon={faSyncAlt} className="service-icon" />

                            <h5>Regelmäßige Updates</h5>

                            <p>Aktualisierungen für maximale Kompatibilität und Leistung Ihrer Website.</p>

                        </div>

                    </div>

                </section>



                {/* Vorteile erläutern */}

                <section className="maintenance-section" data-aos="fade-up" data-aos-delay="200">

                    <h4 className="maintenance-heading">Vorteile unseres Wartungs- und Support-Services</h4>

                    <div className="advantages-grid">

                        <div className="advantage-card">

                            <FontAwesomeIcon icon={faCheckCircle} className="advantage-icon" />

                            <h5>Erhöhte Sicherheit</h5>

                            <p>Durch regelmäßige Sicherheitsupdates und Überwachung bleibt Ihre Website geschützt.</p>

                        </div>

                        <div className="advantage-card">

                            <FontAwesomeIcon icon={faCheckCircle} className="advantage-icon" />

                            <h5>Maximale Leistung</h5>

                            <p>Optimale Performance und Verfügbarkeit Ihrer Website zu jeder Zeit.</p>

                        </div>

                    

                        <div className="advantage-card">

                            <FontAwesomeIcon icon={faCheckCircle} className="advantage-icon" />

                            <h5>Reduzierter Zeitaufwand</h5>

                            <p>Wir übernehmen die technischen Aufgaben, damit Sie sich auf Ihr Kerngeschäft konzentrieren können.</p>

                        </div>

                    </div>

                </section>



                {/* Call-to-Action */}

                <section className="maintenance-section maintenance-cta-section text-center" data-aos="fade-up" data-aos-delay="300">

                    <div className="card">

                        <h4 className="maintenance-heading">Interesse an unseren Wartungs- und Support-Services?</h4>

                        <p className="maintenance-text">

                            Kontaktieren Sie uns, um mehr darüber zu erfahren, wie wir Ihre Website sicher und leistungsfähig halten können.

                        </p>

                        <a href='../#contactus' className="seo-cta-button btn btn-primary btn-lg">Kontaktieren Sie Uns</a>

                    </div>

                </section>

            </div>

        </div>

    );

};



export default MaintenanceSupport; 