import React from 'react';
import './SEO.css';
import './SEOInfographic.css';

const SEOInfographic = () => {
  return (
    <div className="seo-infographic-container" data-aos="fade-up">
      <h4 className="seo-heading">Wichtige SEO-Faktoren</h4>
      <p className="seo-text">
        Die wichtigsten Faktoren, die für eine erfolgreiche SEO-Strategie berücksichtigt werden müssen.
      </p>
      <svg
        width="100%"
        height="400"
        viewBox="0 0 800 400"
        className="seo-svg"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Kreis für On-Page SEO */}
        <circle cx="200" cy="200" r="100" fill="#007bff" />
        <text x="200" y="200" textAnchor="middle" fill="#fff" fontSize="20" dy=".3em">
          On-Page SEO
        </text>

        {/* Kreis für Off-Page SEO */}
        <circle cx="400" cy="200" r="100" fill="#ff6f61" />
        <text x="400" y="200" textAnchor="middle" fill="#fff" fontSize="20" dy=".3em">
          Off-Page SEO
        </text>

        {/* Kreis für Technisches SEO */}
        <circle cx="600" cy="200" r="100" fill="#28a745" />
        <text x="600" y="200" textAnchor="middle" fill="#fff" fontSize="20" dy=".3em">
          Technisches SEO
        </text>

        {/* Verbindungslinien */}
        <line x1="300" y1="200" x2="350" y2="200" stroke="#ccc" strokeWidth="2" />
        <line x1="500" y1="200" x2="550" y2="200" stroke="#ccc" strokeWidth="2" />
      </svg>
    </div>
  );
};

export default SEOInfographic;
