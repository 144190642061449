import React from 'react';
import Slider from 'react-slick';
import './WebDesign.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt, faPaintBrush, faCode, faCogs, faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import image1 from "../../assets/officeexabyte.jpeg"
import SEO from '../../containers/SEO/SEO';

const WebDesign = () => {
  const services = [
    { icon: faMobileAlt, title: "Responsive Webdesign", description: "Wir erstellen mobilfreundliche Websites, die auf allen Geräten gut aussehen.", image: "/images/responsive-design.jpg" },
    { icon: faPaintBrush, title: "UX & UI Design", description: "Nutzerzentrierte Designs, die intuitiv bedienbar und optisch ansprechend sind.", image: "/images/ux-ui-design.jpg" },
    { icon: faCode, title: "Technische Umsetzung", description: "Sauberer Code, schnelle Ladezeiten und moderne Technologien wie React und Bootstrap.", image: "/images/technical-implementation.jpg" },
    { icon: faCogs, title: "CMS-Integration", description: "Wir bieten Ihnen flexible Lösungen, basierend auf Content-Management-Systemen.", image: "/images/cms-integration.jpg" },
    { icon: faClipboardCheck, title: "SEO-Optimierung", description: "Verbessern Sie Ihre Sichtbarkeit in Suchmaschinen durch optimierte Inhalte.", image: "/images/seo-optimization.jpg" }
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (

    
    <div className="container" id="web-design">
      <SEO 
        title="Professionelles Webdesign in Bad Hersfeld" 
        description="Exabyte Webservices bietet modernes und individuelles Webdesign in Bad Hersfeld – für Unternehmen, die online erfolgreich sein wollen." 
      />
      <div className="services">
        

        {/* Einleitender Abschnitt mit Bild */}
        <section className="web-design-section" data-aos="fade-up">
          <div className="card">
           
            <h4 className="web-design-heading">Professionelles Webdesign in Bad Hersfeld</h4>
            <p className="web-design-text">
              Exabyte Webservices bietet maßgeschneiderte Weblösungen, die sowohl ästhetisch ansprechend als auch funktional sind. 
              Als erfahrene Webagentur in Bad Hersfeld sind wir Ihr lokaler Partner für die Erstellung von professionellen Websites.
            </p>
             <img src={image1} alt="Webdesign Header" className="intro-image" />
          </div>
        </section>

        {/* Karussell für Dienstleistungen mit Bild */}
        <section className="web-design-section" data-aos="fade-up" data-aos-delay="100">
          <h4 className="web-design-heading">Unsere Leistungen im Überblick</h4>
          <Slider {...settings} className="carousel-slider">
            {services.map((service, index) => (
              <div key={index} className="card equal-height-card">
             
                <FontAwesomeIcon icon={service.icon} className="service-icon" />
                <h5>{service.title}</h5>
                <p>{service.description}</p>
              </div>
            ))}
          </Slider>
        </section>

        {/* Vorteile und Alleinstellungsmerkmale */}
        <section className="web-design-section" data-aos="fade-up" data-aos-delay="200">
          <div className="card">
            <h4 className="web-design-heading">Warum Exabyte Webservices?</h4>
            <p className="web-design-text">
              Als lokale Webagentur in Bad Hersfeld kennen wir die Bedürfnisse regionaler Unternehmen genau. 
              Wir bieten eine persönliche Beratung, maßgeschneiderte Lösungen und einen kompletten Service 
              von der Konzeption über die Umsetzung bis zur laufenden Wartung.
            </p>
          </div>
        </section>

        {/* Prozessablauf - Karussell mit Bild */}
        <section className="web-design-section" data-aos="fade-up" data-aos-delay="300">
          <h4 className="web-design-heading">Unser Prozessablauf</h4>
          <Slider {...settings} className="carousel-slider">
            {services.map((service, index) => (
              <div key={index} className="card equal-height-card">
           
                <FontAwesomeIcon icon={service.icon} className="process-icon" />
                <h5>{service.title}</h5>
                <p>{service.description}</p>
              </div>
            ))}
          </Slider>
        </section>

        {/* Call-to-Action */}
        <section className="web-design-section web-design-cta-section text-center" data-aos="fade-up" data-aos-delay="400">
          <div className="card">
            <h4 className="web-design-heading">Bereit für Ihre neue Website?</h4>
            <p className="web-design-text">
              Kontaktieren Sie uns noch heute, um ein unverbindliches Beratungsgespräch zu vereinbaren 
              und mehr über unsere Dienstleistungen zu erfahren.
            </p>
            <a href='../#contactus' className="seo-cta-button btn btn-primary btn-lg">Kontaktieren Sie Uns</a>
            </div>
        </section>
      </div>
    </div>
  );
};

export default WebDesign;
