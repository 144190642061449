import React from 'react';
import './Hero.css';
import HeroImage from '../../assets/hero.jpg';

const Hero = () => {
    return (
        <div id="hero" className="hero">
            <div className="hero-overlay"></div>
            <div className="hero-content">
                {/* Kürzere & optimierte H1-Überschrift */}
                <h1 className="hero-title">Exabyte Systems – Webdesign aus Bad Hersfeld</h1>
                
                <p className="hero-description">
                    Professionelle Weblösungen für Unternehmen – modern, schnell & individuell.
                </p>

                <a href="#services" className="cta-button">Mehr erfahren</a>
            </div>
            <div className="hero-image-container">
                <img src={HeroImage} alt="Professionelles Webdesign und SEO von Exabyte Systems in Bad Hersfeld" className="hero-image" />
            </div>
        </div>
    );
}

export default Hero;
