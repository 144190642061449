import React, { useState, useEffect } from 'react';
import './Navbar.css';
import logo from '../../assets/logo.svg';
import DarkMode from '../darkmodetoggler/darkmode';

const Navbar = () => {
    const [hideNavbar, setHideNavbar] = useState(false);
    const [scrollTimeout, setScrollTimeout] = useState(null);
    const [sideMenuOpen, setSideMenuOpen] = useState(false); // State für die Sidebar

    // Scroll-Effekt-Handler
    const handleScroll = () => {
        setHideNavbar(false);

        if (scrollTimeout) {
            clearTimeout(scrollTimeout);
        }

        const timeout = setTimeout(() => {
            setHideNavbar(true);
        }, 3000); // 3 Sekunden nach dem letzten Scrollen

        setScrollTimeout(timeout);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            if (scrollTimeout) {
                clearTimeout(scrollTimeout);
            }
        };
    }, [scrollTimeout]);

    // Toggle-Sidebar
    const toggleSidebar = () => {
        setSideMenuOpen(!sideMenuOpen);
    };

    return (
        <>
            <nav className={`navbar navbar-expand-lg fixed-top ${hideNavbar ? 'hide-navbar' : ''}`}>
                <a className="navbar-brand" href="/">
                    <img src={logo} className="imgLogo" alt="Exabyte Systems" />
                </a>
                <button
                    className="navbar-toggler"
                    type="button"
                    onClick={toggleSidebar} // Öffnet die Sidebar
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="#hero">
                                Home
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#portfolio">
                                Portfolio
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#services">
                                Dienstleistungen
                            </a>
                        </li>
                        

                        <li className="nav-item">
                            <a className="nav-link" href="#contactus">
                                Kontakt
                            </a>
                        </li>
                        <li className="nav-item">
                        </li>
                    </ul>
                </div>
            </nav>

            {/* Sidebar */}
            <div className={`side-menu ${sideMenuOpen ? 'open' : ''}`} >
                <button className="close-btn" onClick={toggleSidebar}>
                    &times;
                </button>
                <ul>
                    <li>
                        <a href="#hero" onClick={toggleSidebar}>
                            Home
                        </a>
                    </li>
                    <li>
                        <a href="#portfolio" onClick={toggleSidebar}>
                            Portfolio
                        </a>
                    </li>
                    <li>
                        <a href="#contactus" onClick={toggleSidebar}>
                            Kontakt
                        </a>
                    </li>
                    <li>
                        <DarkMode />
                    </li>
                </ul>
            </div>

            {sideMenuOpen && <div className="backdrop" onClick={toggleSidebar}></div>}
        </>
    );
};

export default Navbar;
