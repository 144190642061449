import React from 'react';
import SEO from '../../containers/SEO/SEO';
import './IndividualWebapplication.css';
import image1 from '../../assets/webapp.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs, faLaptopCode, faChartLine } from '@fortawesome/free-solid-svg-icons';

const IndividualWebApplications = () => {
    return (
        <div className="container" id="individual-web-applications">
             <SEO 
        title="Individuelle Webanwendungen in Bad Hersfeld" 
        description="Exabyte Webservices entwickelt maßgeschneiderte Webanwendungen für Unternehmen in Bad Hersfeld – effizient, innovativ und skalierbar." 
      />
            <div className="services">
                <h1 data-aos="fade-up">Individuelle Webanwendungen</h1>

                {/* Einleitender Abschnitt */}
                <section className="webapp-section" data-aos="fade-up">
    <div className="card">
        <h4 className="webapp-heading">Entwicklung maßgeschneiderter Webanwendungen</h4>
        <p className="webapp-text">
            Unsere maßgeschneiderten Webanwendungen sind speziell auf die Anforderungen Ihres Unternehmens in Bad Hersfeld zugeschnitten. 
            Wir bieten digitale Lösungen, die Ihre Geschäftsprozesse optimieren und Ihnen helfen, effizienter zu arbeiten. 
            Durch unsere Expertise im Bereich SEO und Webentwicklung sorgen wir dafür, dass Ihre digitale Präsenz in Bad Hersfeld 
            optimal gefunden wird und Ihr Unternehmen nachhaltig wächst. Setzen Sie auf uns, um Ihre Online-Präsenz gezielt zu stärken 
            und den digitalen Erfolg in Bad Hersfeld und darüber hinaus sicherzustellen.
        </p>
        <img src={image1} alt="Webanwendung" className="intro-image" />
    </div>

</section>


                {/* Leistungen */}
                <section className="webapp-section" data-aos="fade-up" data-aos-delay="100">
                    <h4 className="webapp-heading">Unsere Leistungen für individuelle Webanwendungen</h4>
                    <div className="card-container">
                        <div className="card small-card">
                            <FontAwesomeIcon icon={faCogs} className="service-icon" />
                            <h5>Flexibilität & Anpassbarkeit</h5>
                            <p>Entwicklung vollständig anpassbarer Webanwendungen für Ihre spezifischen Geschäftsanforderungen.</p>
                        </div>

                        <div className="card small-card">
                            <FontAwesomeIcon icon={faLaptopCode} className="service-icon" />
                            <h5>Benutzerfreundliches Design</h5>
                            <p>Erstellung intuitiver, benutzerfreundlicher Designs für eine bessere Benutzererfahrung.</p>
                        </div>

                        <div className="card small-card">
                            <FontAwesomeIcon icon={faChartLine} className="service-icon" />
                            <h5>Skalierbarkeit & Wachstum</h5>
                            <p>Webanwendungen, die mit Ihrem Unternehmen wachsen und sich an neue Anforderungen anpassen.</p>
                        </div>
                    </div>
                </section>

                {/* Vorteile */}
                <section className="webapp-section" data-aos="fade-up" data-aos-delay="200">
                    <h4 className="webapp-heading">Vorteile unserer individuellen Webanwendungen</h4>
                    <div className="advantages-grid">
                        <div className="advantage-card">
                            <FontAwesomeIcon icon={faCogs} className="advantage-icon" />
                            <h5>Effiziente Prozesse</h5>
                            <p>Optimierte Prozesse und erhöhte Effizienz für Ihre internen Abläufe.</p>
                        </div>

                        <div className="advantage-card">
                            <FontAwesomeIcon icon={faLaptopCode} className="advantage-icon" />
                            <h5>Verbesserte Nutzererfahrung</h5>
                            <p>Attraktive, benutzerzentrierte Anwendungen für eine höhere Kundenzufriedenheit.</p>
                        </div>

                        <div className="advantage-card">
                            <FontAwesomeIcon icon={faChartLine} className="advantage-icon" />
                            <h5>Skalierbare Architektur</h5>
                            <p>Wachstumsfähige und nachhaltige Webanwendungen für langfristigen Erfolg.</p>
                        </div>
                    </div>
                </section>

                {/* Call-to-Action */}
                <section className="webapp-section webapp-cta-section text-center" data-aos="fade-up" data-aos-delay="300">
                    <div className="card">
                        <h4 className="webapp-heading">Interesse an individuellen Webanwendungen?</h4>
                        <p className="webapp-text">
                            Kontaktieren Sie uns, um mehr darüber zu erfahren, wie wir Ihre Vision in die Realität umsetzen können.
                        </p>
                        <a href='../#contactus' className="seo-cta-button btn btn-primary btn-lg">Kontaktieren Sie Uns</a>
                        </div>
                </section>
            </div>
        </div>
    );
};

export default IndividualWebApplications;
