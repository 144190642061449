import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Rechtliches from './pages/rechtliches/rechtliches';
import { Navbar, Hero, Portfolio, Aboutus, Services, Pricing, Blog, FAQ, CTA, Contact, Testimonials, SEOArticle, ParallaxScrolling, Trends, Comparison } from './containers';
import Webdesign from './pages/Design/WebDesign';
import MaintenanceSupport from './pages/MaintenanceSupport/MaintenanceSupport';
import ECommerce from './pages/ECommerce/ECommerce';
import IndividualWebApplications from './pages/IndividualWebApplication/IndividualWebapplication';
import WebHostingDomainManagement from './pages/WebHostingDomainManagement.jsx/WebHostingDomainManagement';
import SEOInfographic from './pages/SEO/SEOInfographic';
import './index.css';

import './App.css';

// Neue Projektseite
const Projects = () => {
  return (
    <div>
      <h1>Unsere Projekte</h1>
      <p>Hier findest du eine Liste unserer Projekte.</p>
      {/* Füge hier dein Projekt-Layout oder Portfolio hinzu */}
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <div className="App">
        <Navbar />
        {/* Der Hauptinhalt wechselt zwischen den Seiten */}
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Hero />
                <Aboutus />
                <Services />
                <Portfolio />
                <Pricing />
                <Comparison />
                <Testimonials />
                <Blog />
                <FAQ />
                <Contact />
              </>
            }
          />
          {/* Neue Projektseite */}
          <Route path="/rechtliches" element={<Rechtliches />} />
          <Route path="/trendsWebdesign" element={<Trends />} /> 
          <Route path="/SEOArticle" element={<SEOArticle />} />
          <Route path="/ParallaxScrolling" element={<ParallaxScrolling />} />
          <Route path="/SEO" element={<SEOInfographic />} />
          <Route path="/design" element={<Webdesign />} />
          <Route path="/support" element={<MaintenanceSupport />} />
          <Route path="/ecommerce" element={<ECommerce />} />
          <Route path="/individual" element={<IndividualWebApplications />} />
          <Route path="/webhosting" element={<WebHostingDomainManagement />} />
          <Route path="/impressum" element={<Rechtliches />} />
          <Route path="/datenschutz" element={<Rechtliches />} />
                    <Route path="*" element={<div>Seite nicht gefunden</div>} />
        </Routes>
  
        <CTA />
      </div>
    </Router>
  );
};

export default App;
